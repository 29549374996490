import React, { useState, useEffect } from "react";
import "../styles/Chatbot.css";
import chatbotIcon from "../assets/chatbot-icon.png";
import { Brightness4 } from "@mui/icons-material";
import predefinedResponses from "./PredefinedResponses";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [darkMode, setDarkMode] = useState(true); // Dark mode by default
  const [showSuggestions, setShowSuggestions] = useState(false); // Control visibility of suggestions

  // Toggle chatbot visibility
  const toggleChatbot = () => setIsOpen(!isOpen);

  // Toggle dark/light mode
  const toggleDarkMode = () => setDarkMode(!darkMode);

  // Function to check predefined responses
  const findResponse = (userInput) => {
    const lowerInput = userInput.toLowerCase().trim();
    for (const item of predefinedResponses) {
      if (item.intent.some((intent) => lowerInput.includes(intent))) {
        return item.response;
      }
    }
    return null; // Return null if no predefined response is found
  };

  // Extract predefined suggestions (first intent of each predefined response)
  const predefinedSuggestions = predefinedResponses.map((response) =>
    response.intent[0]
  );

  // Handle initial welcome message
  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages((prev) => [
        ...prev,
        {
          role: "bot",
          content: "Hey! How can I help you? Click 'Show Suggestions' for quick questions!"
        }
      ]);
    }
  }, [isOpen, messages]);

  // Fetch ChatGPT response if input is not in predefined responses
  const fetchChatGPTResponse = async (userInput) => {
    try {
      const payload = {
        body: JSON.stringify({
          message: userInput // Properly nested message
        })
      };

      const response = await fetch(
        "https://8215sk5bx7.execute-api.us-east-1.amazonaws.com/dev/chatbot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }
      );

      if (response.ok) {
        const rawData = await response.json();
        if (rawData.body) {
          const parsedBody = JSON.parse(rawData.body);
          return parsedBody.response; // Return the actual response from the parsed body
        } else {
          return "Sorry, I couldn't fetch a response.";
        }
      } else {
        return "Sorry, I couldn't fetch a response.";
      }
    } catch (error) {
      return "An error occurred while fetching the response.";
    }
  };

  // Handle sending messages
  const sendMessage = async () => {
    if (!input.trim()) return;

    // Add the user's message to the chat
    const userMessage = { role: "user", content: input };
    setMessages((prev) => [...prev, userMessage]);

    // Check for predefined responses
    const predefinedResponse = findResponse(input);
    if (predefinedResponse) {
      // Add predefined response to the chat
      const botMessage = { role: "bot", content: predefinedResponse };
      setMessages((prev) => [...prev, botMessage]);
    } else {
      // Call the API if no predefined response is found
      const botResponse = await fetchChatGPTResponse(input);
      const botMessage = { role: "bot", content: botResponse };
      setMessages((prev) => [...prev, botMessage]);
    }

    // Clear the input field
    setInput("");
    setShowSuggestions(false); // Hide suggestions after user sends a message
  };

  return (
    <div className={`chatbot-container ${darkMode ? "dark" : "light"}`}>
      {/* Chatbot toggle button */}
      <button className="chatbot-toggle" onClick={toggleChatbot}>
  <img src={chatbotIcon} alt="Chatbot Icon" className="chatbot-icon" />
</button>
      {isOpen && (
        <div className="chatbot-window">
          {/* Header */}
          <div className="chatbot-header">
            <h4>Ask Artificial Sepehr</h4>
            <span style={{ fontSize: "12px", fontWeight: "300" }}>
              Powered by ChatGPT
            </span>
            <div className="chatbot-controls">
              <Brightness4 style={{ marginRight: "8px", color: "inherit" }} />
              <label className="dark-mode-switch">
                <input
                  type="checkbox"
                  checked={darkMode}
                  onChange={toggleDarkMode}
                />
                <span className="slider"></span>
              </label>
              <button onClick={toggleChatbot} className="close-btn">
                ×
              </button>
            </div>
          </div>
  
          {/* Chat messages */}
          <div className="chatbot-messages">
            {messages.map((msg, idx) => (
              <div
                key={idx}
                className={`chatbot-message ${
                  msg.role === "user" ? "user" : "bot"
                }`}
              >
                {msg.content}
              </div>
            ))}
  
            {/* Show predefined suggestions */}
            {showSuggestions && (
              <div className="suggestions-container">
                {predefinedSuggestions.map((suggestion, idx) => (
                  <button
                    key={idx}
                    className="suggestion-button"
                    onClick={() => {
                      setInput(suggestion); // Set the input to the clicked suggestion
                      sendMessage(); // Automatically send the suggestion
                    }}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
  
          {/* Input field with 'Show Suggestions' text above */}
          <div className="chatbot-input">
            {/* Show Suggestions Text */}
            <span
              className="show-suggestions-text"
              onClick={() => setShowSuggestions(!showSuggestions)}
            >
              {showSuggestions ? "Hide Suggestions" : "Show Suggestions"}
            </span>
  
            {/* Input Field and Send Button */}
            <div className="input-row">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
              />
              <button onClick={sendMessage}>Send</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default Chatbot;
